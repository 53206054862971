// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap';

// Import jQuery
import * as $ from 'jquery';

// Import ScrollTrigger
import { ScrollTrigger } from '../plugins/ScrollTrigger.min';

$(document).ready(function () {
  // console.log("Write your global script here");

  // Global Entry Animation
  // Config to Disable All Warning, Change to true
  gsap.config({
    nullTargetWarn: false,
  });

  // Register Plugin Scroll Trigger
  gsap.registerPlugin(ScrollTrigger);

  // Config for Start Animation
  let startAnimate = {
    autoAlpha: 0,
    toRight: -100,
    toLeft: 100,
    start: 'top 75%',
    end: 'bottom 10%',
  };

  // Config for Finish Animation
  let finishAnimate = {
    autoAlpha: 1,
    yPercent: 0,
    xPercent: 0,
    duration: 0.75,
    stagger: 0.25,
    ease: 'Power4.in',
  };

  gsap.utils.toArray('[data-animation]').forEach((div) => {
    let target = $(div).children().length ? $(div).children(':not([data-animation])') : div;
    // Needed Object Call
    // ScrollTrigger Settings
    let scrollSettings = {
      start: startAnimate.start,
      end: startAnimate.end,
    };

    // Finish Settings
    let animationFinish = {
      autoAlpha: finishAnimate.autoAlpha,
      duration: finishAnimate.duration,
      stagger: finishAnimate.stagger,
      ease: finishAnimate.ease,
    };

    switch ($(div).attr('data-animation')) {
      case 'slide-down':
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            yPercent: startAnimate.toRight,
          },
          {
            yPercent: finishAnimate.yPercent,
            animationFinish,
          }
        );
        break;

      case 'slide-up':
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            yPercent: startAnimate.toLeft,
          },
          {
            yPercent: finishAnimate.yPercent,
            animationFinish,
          }
        );
        break;

      case 'slide-left':
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            xPercent: startAnimate.toLeft,
          },
          {
            xPercent: finishAnimate.xPercent,
            animationFinish,
          }
        );
        break;

      case 'slide-right':
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
            xPercent: startAnimate.toRight,
          },
          {
            xPercent: finishAnimate.xPercent,
            animationFinish,
          }
        );
        break;

      case 'fade-in':
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
          },
          {
            animationFinish,
          }
        );
        break;

      default:
        gsap.from(
          target,
          {
            scrollTrigger: {
              trigger: div,
              scrollSettings,
            },
            autoAlpha: startAnimate.autoAlpha,
          },
          {
            animationFinish,
          }
        );
    }
  });

  // Navbar Js
  function navbar() {
    // scrolling navbar
    $(window).on('scroll', function () {
      
      if ($(this).scrollTop() > 50) {
        $('.js-navbar').css({ background: '#ed752d' });
      }
      if ($(this).scrollTop() > 80) {
        $('.js-navbar').css({
          height: '86px',
          background: '#ed752d', 
        });
      }
    });

    // button open and close
    $('.js-navbar__menu').on('click', function () {
      $(this).css({ display: 'none' });
      $('.js-navbar__close').css({ display: 'block' });
      $('.navbar__links').css({ display: 'flex' });
      $('.navbar__sign').css({ display: 'flex' });
      $('.js-navbar__links').slideDown(500);
    });
    $('.js-navbar__close').on('click', function () {
      $(this).css({ display: 'none' });
      $('.js-navbar__menu').css({ display: 'block' });
      $('.navbar__links').css({ display: 'none' });
      $('.navbar__sign').css({ display: 'none' });
      $('.js-navbar__links').slideUp(500);
    });

    // Dropdown
    // --- Desktop
    $('#js-dropdown-navbar').click(function () {
      let menuSelect = $('#js-dropdown-menu');
      let navSelect = $('#js-dropdown-navbar');
      let conditionActive = navSelect.hasClass('active1');
      let mouseLeaveMenu = menuSelect.mouseleave(function () {
        console.log('Mouse Out of Menu');
        navSelect.removeClass('active1');
        $(this).slideUp({
          duration: 500,
        });
      });

      conditionActive | mouseLeaveMenu
        ? ($(this).removeClass('active1'),
          menuSelect.slideUp({
            duration: 500,
          }))
        : ($(this).addClass('active1'),
          menuSelect.slideDown({
            duration: 500,
            start: function () {
              $(this).css('display', 'flex');
            },
          }));
    });

    // --- Mobile
    $('#js-dropdown').click(function () {
      $('#js-dropdown2, #js-dropdown-arrow2, .navbar__sign').toggleClass('active');
    });
  }

  function init() {
    navbar();
  }

  init();
});


// script to show email form
document.addEventListener('DOMContentLoaded', function () {
  const urlParams = new URLSearchParams(window.location.search);
  const linkedinParam = urlParams.get('linkedin');

  if (linkedinParam === 'true') {
    // Delay the modal show by 5 seconds
    setTimeout(() => {
      const linkedinModal = new bootstrap.Modal(document.getElementById('linkedinModal'));
      linkedinModal.show();
    }, 20000); // 5000 milliseconds = 5 seconds
  }

  try {
    const form = document.getElementById('linkedinForm'); // Replace 'linkedinForm' with your actual form ID
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      const myForm = event.target;
      const formData = new FormData(myForm);
      // Handle form submission logic here.
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {window.location.href = './health-safety-self-assessment';})
        .catch((error) => alert(error));
        // Redirect to another page after form submission
      // window.location.href = './health-safety-self-assessment';
    });
  
  }
  catch(err) {}

  try {
    const form2 = document.getElementById('ltifr-calculator-form');
  
    form2.addEventListener('submit', function (event) {
      event.preventDefault();
      
      const hours_worked_financial_year = 1000000;
      
      let result = ( document.getElementById("lost_time_number").value / document.getElementById("total_hours_worked").value ) * hours_worked_financial_year;
      
      // Assume the input field where you want to display the result has an ID of 'ltfir'
      document.getElementById("ltfir").value = result.toFixed(2);
    });
  
  }
  catch(err) {
    // Handle errors if necessary
  }
  

  try {

    const videoModal_btn = document.querySelector("#videoModal_btn");
    videoModal_btn.addEventListener('click', function (event) {

      event.preventDefault();
      const modal3 = bootstrap.Modal.getOrCreateInstance('#videoModal');
      modal3.show();

      $("#modalVideo").get(0).play();

    });

    $("#videoModal").on("hidden.bs.modal", (e) => {
      $("#modalVideo").get(0).currentTime = 0;
      $("#modalVideo").get(0).pause();
    });


  }
  catch(err){

  }

  try {



    $(".videoModal_btn").on('click', function (event) {

      event.preventDefault();
      const modal4 = bootstrap.Modal.getOrCreateInstance('#videoModal');
      modal4.show();

      $("#modalVideo").get(0).play();

    });


    $("#videoModal").on("hidden.bs.modal", (e) => {
      $("#modalVideo").get(0).currentTime = 0;
      $("#modalVideo").get(0).pause();
    });


  }
  catch(err){

  }

  try {
    $("#fatigue_form").on("submit",(e) => {
      e.preventDefault();
      let email = $("#email").val();
      let sleep24 = $("#sleep24").val();
      let sleep48 = $("#sleep48").val();
      let awake = $("#awake").val();

      $.ajax({
        type: 'GET',
        url: 'https://filipiknow.net/smtp/index.php',
        data : { email: email, sleep24: sleep24, sleep48: sleep48, awake: awake},
        crossDomain: true,
        beforeSend: function() {
          $("#submit_btn").prop("disabled","disabled");
          $("#submit_btn").html('<i class="fa-solid fa-circle-notch fa-spin"></i> Please wait...');
        },
        success: function(data) {
          $("#fatigue_form_success").prop('class','shadow-sm text-center');
          $("#fatigue_form").hide();
          $("#submit_btn").html("Submit");
          $("#submit_btn").prop("disabled",null);
        },
        error: function(){
          $("#submit_btn").html("Submit");
          $("#submit_btn").prop("disabled",null);
        }
      })

    });
  }
  catch(err) {

  }

});
